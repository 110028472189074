/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
	background: #1B2A43;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
